import React from 'react';
import {
  SelectField,
  DateField,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import FieldsGroup from '../utils/FieldsGroup';
import CurrencyField from '../utils/CurrencyField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';

import { useConstantContext } from '../ConstantsContext';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

const FinancingDetailsGroup = () => {
  const { choices } = useConstantContext();
  const { isMerchant } = useUserPermissionsAndRoles();
  const { t } = useTranslation();

  return (
    <FieldsGroup title={t('Financing details')}>
      <Grid container>
        {isMerchant && (
          <Grid item lg={4} md={6}>
            <SimpleShowLabeled>
              <ReferenceField
                label={t('Financer')}
                source="financing.financedBy"
                reference="entity-v2/core"
                emptyText="-"
                link={false}
              >
                <FunctionField render={({ name }) => `${name}`} />
              </ReferenceField>
            </SimpleShowLabeled>
          </Grid>
        )}
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <CurrencyField source="financing.amount" currencySource="financing.currency" emptyText="0.00" label={t('Financed amount')} />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <DateField source="financing.financedAt" label={t('Financing date')} emptyText="-" />
          </SimpleShowLabeled>
        </Grid>
        <Grid item lg={4} md={6}>
          <SimpleShowLabeled>
            <SelectField source="financialProduct" label={t('Financial product')} choices={choices.financialProducts} />
          </SimpleShowLabeled>
        </Grid>
      </Grid>
    </FieldsGroup>
  );
};

export default FinancingDetailsGroup;
