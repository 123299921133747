import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ArrayInput,
  ReferenceInput,
  required,
} from 'react-admin';

import { useConstantContext } from '../ConstantsContext';

import InputsGroup from '../designSystem/InputsGroup';

import dayjs from '../../utils/dayjs';
import renderReference from '../../utils/renderReference';
import { positiveNumberValidation } from '../../utils/validationErrors';

import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import SimpleFormIterator from '../designSystem/react-admin/SimpleFormIterator';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import NumberInput from '../designSystem/react-admin/inputs/NumberInput';
import DateInput from '../designSystem/react-admin/inputs/DateInput';

import resources from '../../constants/resources';

const validateRequired = [required()];

const InsuranceFields = ({ entityResource }) => {
  const { constants, choices } = useConstantContext();
  const { currencies, creditScoreProviders } = choices;
  const { entityRoles } = constants;
  const { t } = useTranslation();

  if (!entityResource.endsWith(resources.CUSTOMER)) return null;

  return (
    <InputsGroup title={t('Insurance')}>
      <ArrayInput
        source="insurances"
        label={false}
      >
        <SimpleFormIterator>
          <InputsGroup layout="column">
            <ReferenceInput
              source="entityOwnerId"
              reference="entity-v2/reference"
              filter={{
                entityRoles: [entityRoles.MERCHANT, entityRoles.FINANCER],
              }}
            >
              <AutocompleteInput
                label={t('Owner')}
                validate={validateRequired}
                optionText={renderReference}
                suggestionLimit={20}
              />
            </ReferenceInput>

            <ReferenceInput
              source="assignedMerchantId"
              reference="entity-v2/reference"
              filter={{ entityRoles: entityRoles.MERCHANT }}
            >
              <AutocompleteInput
                label={t('Assigned merchant')}
                optionText={renderReference}
                suggestionLimit={20}
              />
            </ReferenceInput>

            <SelectInput
              label={t('Provider')}
              source="insuranceProvider"
              choices={creditScoreProviders}
              validate={validateRequired}
            />

            <NumberInput
              label={t('Insurance limit')}
              source="creditInsuranceLimit"
              validate={[positiveNumberValidation, ...validateRequired]}
            />

            <SelectInput
              label={t('Insurance currency')}
              source="creditInsuranceLimitCurrency"
              choices={currencies}
              validate={validateRequired}
            />

            <DateInput
              label={t('Start date')}
              source="insuranceStartDate"
              parse={(value) => value}
              format={(value) => dayjs(value).format('YYYY-MM-DD')}
              defaultValue={null}
            />

            <DateInput
              label={t('End date')}
              source="insuranceEndDate"
              parse={(value) => value}
              format={(value) => dayjs(value).format('YYYY-MM-DD')}
              defaultValue={null}
            />
          </InputsGroup>
        </SimpleFormIterator>
      </ArrayInput>
    </InputsGroup>
  );
};

InsuranceFields.propTypes = {
  entityResource: PropTypes.string.isRequired,
};

export default InsuranceFields;
