import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import TitleH5 from '../designSystem/TitleH5';
import { DATE_WITH_HOUR_FORMAT } from '../../theme';

import AddCommentButton from '../utils/AddCommentButton';
import FieldsGroup from '../utils/FieldsGroup';

import dayjs from '../../utils/dayjs';

const CommentsGroup = () => {
  const { comments } = useRecordContext();
  const { t } = useTranslation();

  return (
    <FieldsGroup>
      <TitleH5 title={t('Oldest first')} />
      {comments.map((comment) => (
        <Box marginBottom="2rem">
          <Box display="flex" alignItems="flex-end" marginBottom="0.5rem">
            <Typography fontWeight="600" fontSize="15px">{comment.author}</Typography>
            <Typography marginLeft="1rem" variant="caption">{dayjs(comment.createdAt).format(DATE_WITH_HOUR_FORMAT)}</Typography>
          </Box>
          <Typography>{comment.message}</Typography>
        </Box>
      ))}
      <AddCommentButton />
    </FieldsGroup>
  );
};

export default CommentsGroup;
