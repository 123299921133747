import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
  useRecordContext,
  ReferenceField,
  FunctionField,
} from 'react-admin';

import dayjs from '../../../utils/dayjs';
import FieldsGroup from '../../utils/FieldsGroup';

import { DATE_WITH_HOUR_FORMAT } from '../../../theme';

const Note = () => {
  const record = useRecordContext();
  const { t } = useTranslation();

  return (
    record.note ? (
      <FieldsGroup title={t('Note')}>
        <Box marginBottom="2rem">
          <Box display="flex" alignItems="flex-end" marginBottom="0.5rem">
            <ReferenceField
              label={t('Requesting user')}
              source="applicant"
              reference="user/public"
              link={false}
            >
              <FunctionField render={(user) => <Typography fontWeight="600" fontSize="15px">{user.firstName} {user.lastName}</Typography>} />
            </ReferenceField>
            <Typography marginLeft="1rem" variant="caption">{dayjs(record.createdAt).format(DATE_WITH_HOUR_FORMAT)}</Typography>
          </Box>
          <Typography>{record.note}</Typography>
        </Box>
      </FieldsGroup>
    ) : null
  );
};

export default Note;
