import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../designSystem/ModalButton';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useCustomRpc from '../hooks/useCustomRpc';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import resources from '../../constants/resources';
import { useConstantContext } from '../ConstantsContext';

import KycFields from '../utils/KycFields';
import InsuranceFields from '../utils/InsuranceFields';
import InsuranceSettingsFields from '../utils/InsuranceSettingsFields';

const UpdateKycButton = ({ entityResource }) => {
  const record = useRecordContext();
  const {
    entityDetailsId,
    kyc,
    insurances,
    settings,
  } = record;
  const {
    creditScore,
    creditScoreProvider,
    paymentScore,
    paymentScoreProvider,
    financerScore,
    expectedVolume,
    ratingProvider,
    rating,
    creditQualityStep,
  } = kyc;

  const { hasFactoring } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canEditCustomerKyc = useHasUserPermissions(
    constants,
    userActions.SET_CUSTOMER_KYC,
  );
  const canEditMerchantKyc = useHasUserPermissions(
    constants,
    userActions.SET_MERCHANT_KYC,
  );

  const {
    mutate: updateKycInfo,
    isLoading: isKycLoading,
  } = useCustomRpc({
    path: `entity-v2/${entityDetailsId}/kyc-info`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update the kyc information'),
  });

  const {
    mutate: updateInsurances,
    isLoading: isInsurancesLoading,
  } = useCustomRpc({
    path: `entity-v2/${entityDetailsId}/insurance`,
    httpMethod: 'PUT',
    shouldRefresh: true,
    errorMessage: t('Could not update insurances'),
  });

  const onSubmit = useCallback((formData) => {
    const kycData = {
      kyc: {
        creditScore: formData.creditScore,
        creditScoreProvider: formData.creditScoreProvider,
        paymentScore: formData.paymentScore,
        paymentScoreProvider: formData.paymentScoreProvider,
        financerScore: formData.financerScore,
        expectedVolume: formData.expectedVolume,
        insuranceStartDate: formData.insuranceStartDate,
        insuranceEndDate: formData.insuranceEndDate,
        ratingProvider: formData.ratingProvider,
        rating: formData.rating,
        creditQualityStep: formData.creditQualityStep,
      },
    };
    const insurancesData = {
      insurances: formData.insurances,
      settings: formData.settings,
    };
    updateKycInfo(kycData);
    if (entityResource.endsWith(resources.CUSTOMER)) updateInsurances(insurancesData);

    return true;
  }, [updateKycInfo, entityResource, updateInsurances]);

  if (!canEditMerchantKyc && entityResource.endsWith(resources.MERCHANT)) return null;
  if (!canEditCustomerKyc && entityResource.endsWith(resources.CUSTOMER)) return null;
  if (!hasFactoring) return null;

  return (
    <EditModalButton
      modalTitle={t('Insurance and KYC')}
      onClick={onSubmit}
      disabled={isKycLoading || isInsurancesLoading}
      width="48rem"
      formDefaultValue={{
        creditScore,
        creditScoreProvider,
        paymentScore,
        paymentScoreProvider,
        financerScore,
        expectedVolume,
        ratingProvider,
        rating,
        creditQualityStep,
        insurances,
        settings,
      }}
    >
      <KycFields />
      <InsuranceFields entityResource={entityResource} />
      <InsuranceSettingsFields entityResource={entityResource} />
    </EditModalButton>
  );
};

UpdateKycButton.propTypes = {
  entityResource: PropTypes.string.isRequired,
};

export default UpdateKycButton;
