import React from 'react';

import {
  FormTab,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import OutboundPaymentFileList from '../file/OutboundPaymentFileList';

import renderReference from '../../utils/renderReference';
import CurrencyField, { colorSwticherFlag } from '../utils/CurrencyField';

import DownloadOutboundPaymentButton from '../payment/DownloadOutboundPaymentButton';

import { useConstantContext } from '../ConstantsContext';

import useHasUserPermissions from '../hooks/useHasUserPermissions';

import List from '../designSystem/react-admin/list/List';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';
import DateField from '../designSystem/react-admin/DateField';
import AutocompleteInput from '../designSystem/react-admin/inputs/AutocompleteInput';
import ReferenceInput from '../designSystem/react-admin/inputs/ReferenceInput';
import TabbedForm from '../designSystem/react-admin/TabbedForm';
import { DatagridConfigurable } from '../designSystem/react-admin/Datagrid';
import TitleH1 from '../designSystem/TitleH1';

export const getSettlementAccountListTitle = (t) => t('Accounts');

const SettlementAccountList = () => {
  const { constants } = useConstantContext();
  const { entityRoles, userActions } = constants;
  const { t } = useTranslation();

  const canEditSettlement = useHasUserPermissions(
    constants,
    userActions.MANAGE_SETTLEMENT_ACCOUNTS,
  );

  return (
    <TabbedForm toolbar={null} sanitizeEmptyValues>
      <FormTab label={t('Accounts')} sx={{ alignItems: 'stretch' }}>
        <List
          disableSyncWithLocation
          sort={{ field: 'createdAt', order: 'DESC' }}
          filter={{ closedAt: { $exists: false } }}
          mainFilterInputs={[
            <ReferenceInput
              source="debtorId"
              reference="entity-v2/reference"
              filter={{ entityRoles: entityRoles.MERCHANT }}
              alwaysOn
            >
              <AutocompleteInput
                optionText={renderReference}
                suggestionLimit={20}
                placeholder={t('Merchant')}
              />
            </ReferenceInput>,
          ]}
          appLocation="home.settlement-account"
          title={<TitleH1 title={getSettlementAccountListTitle(t)} />}
        >
          <DatagridConfigurable
            bulkActionButtons={canEditSettlement ? (
              <DownloadOutboundPaymentButton
                variant="contained"
                size="small"
                color="primary"
                disabledTooltip="Select accounts to payout"
              />
            ) : false}
            rowClick="show"
            rightAlignedColumns={['balance']}
          >
            <EntityReferenceField
              label={t('Company')}
              source="debtorId"
              reference="entity-v2"
            />
            <DateField source="createdAt" label={t('Creation date')} />
            <CurrencyField
              colorSwticherFlag={colorSwticherFlag.SETTLEMENT_ACCOUNT}
              source="balance"
              currencySource="currency"
              label={t('Balance')}
            />
          </DatagridConfigurable>
        </List>
      </FormTab>
      <FormTab label={t('Outbound payment files')} sx={{ alignItems: 'stretch' }}>
        <OutboundPaymentFileList />
      </FormTab>
    </TabbedForm>
  );
};

export default SettlementAccountList;
