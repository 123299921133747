import { t } from 'i18next';
import dayjs from '../../utils/dayjs';

const validateStartEndDateInSameYear = () => (fieldValue, formValues) => {
  const { filters } = formValues;

  if (dayjs(filters.startDate).year() !== dayjs(filters.endDate).year()) return t('Start date and end date need to be on the same year');

  return '';
};

export default validateStartEndDateInSameYear;
