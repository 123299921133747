import { getJWTPayload } from './utils/jwt';
import config from '../config';
import getAccessToken from './utils/getAccessToken';
import setLanguage from './utils/setLanguage';
import store from './utils/store';

const authUser = {
  login: async ({ username, password }) => {
    const request = new window.Request(`${config.apiBase}/authorization/authenticate`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new window.Headers({
        'Content-Type': 'application/json',
        'X-API-Key': config.apiKey,
      }),
    });
    const response = await window.fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const json = await response.json();
    const { data: { token, refreshToken } } = json;
    store.setItem('token', token);
    store.setItem('refreshToken', refreshToken);
    setLanguage(token);
  },
  logout: async () => {
    store.removeItem('token');
    store.removeItem('refreshToken');
    window.history.replaceState({}, '', window.location.pathname);
  },
  checkError: async ({ status }) => {
    if (status === 401) {
      store.removeItem('token');
      store.removeItem('refreshToken');
      throw new Error('Invalid credentials. Please login again');
    }
  },
  checkAuth: async () => {
    const token = await getAccessToken();
    if (!token) throw new Error('Not logged in');
    const expireTimestamp = getJWTPayload(token).exp;
    const now = Date.now();
    if (expireTimestamp * 1000 < now) throw new Error('Session expired');
  },
  getPermissions: async () => {
    const token = await getAccessToken();
    if (!token) {
      return {
        user_allowedActions: [], modules: [], entityRoles: [],
      };
    }
    const {
      // eslint-disable-next-line camelcase
      user_allowedActions,
      modules,
      entity_roles: entityRoles,
    } = getJWTPayload(token);

    if (
      // eslint-disable-next-line camelcase
      !user_allowedActions
      || !modules
      || !entityRoles
    ) throw new Error('Missing permissions in authentication token');

    const userAllowedPermissions = new Map(user_allowedActions);

    return {
      userAllowedPermissions, modules, entityRoles,
    };
  },
};

export default authUser;
