import React from 'react';
import {
  ArrayField,
  TextField,
  useRecordContext,
  WrapperField,
  EditButton,
  ShowButton,
} from 'react-admin';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
} from '@mui/material';

import CurrencyField from '../utils/CurrencyField';
import FieldsGroup from '../utils/FieldsGroup';

import SimpleShowLayout from '../designSystem/react-admin/SimpleShowLayout';
import Datagrid from '../designSystem/react-admin/Datagrid';
import Edit from '../designSystem/react-admin/Edit';
import TitleH1 from '../designSystem/TitleH1';
import DateField from '../designSystem/react-admin/DateField';
import { useConstantContext } from '../ConstantsContext';
import DropDownMenuUnidentifiedAccount from '../utils/DropDownMenuUnidentifiedAccount';
import IdentifyButton from './IdentifyButton';

export const getUnidentifiedAccountTitle = (record) => {
  if (!record) return '';
  if (record.reference) return record.reference;
  if (record.payerName) {
    return `${record.payerName} - ${record.currency} ${record.amount}`;
  }
  return record.id;
};

const Title = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const title = `${t('Unidentified payment')}: ${getUnidentifiedAccountTitle(record)}`;

  return <TitleH1 title={title} />;
};

const AccountLink = () => {
  const { constants } = useConstantContext();
  const { accountTypes } = constants;
  const record = useRecordContext();

  const { identified: { accountId, reference, accountType } } = record;
  const isOrder = accountType === accountTypes.ORDER;

  if (!accountId) return null;

  return (
    <>
      {
        isOrder ? (
          <EditButton
            resource="order"
            variant="blueBackgroundOnHover"
            record={{ id: accountId }}
            label={reference}
            sx={{ '& .MuiButton-startIcon': { display: 'none' } }}
          />
        ) : (
          <ShowButton
            resource="settlement-account"
            variant="blueBackgroundOnHover"
            record={{ id: accountId }}
            label={reference}
            sx={{ '& .MuiButton-startIcon': { display: 'none' } }}
          />
        )
      }
    </>
  );
};

const UnidentifiedAccountFieldsGroup = () => {
  const {
    transactions,
    id,
    balance,
    currency,
  } = useRecordContext();
  const { t } = useTranslation();

  return (
    <FieldsGroup>
      <ArrayField source="transactions">
        <Datagrid
          bulkActionButtons={false}
          rightAlignedColumns={['amount', 'actions']}
        >
          <DateField source="date" label={t('Date')} showTime />
          <TextField source="description" />
          <WrapperField label={t('Order/settlement')} source="action">
            <AccountLink />
          </WrapperField>
          <TextField source="payinDetails.reference" label={t('Payment Reference')} />
          <TextField source="payinDetails.payerName" label={t('Payer name')} />
          <CurrencyField source="amount" currencySource="currency" label={t('Amount')} />
          <WrapperField label={false} source="actions">
            <DropDownMenuUnidentifiedAccount
              transactions={transactions}
              accountId={id}
              balance={balance}
              currency={currency}
            />
          </WrapperField>
        </Datagrid>
      </ArrayField>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        paddingY="1.25rem"
        paddingX="0.5rem"
      >
        <Box display="flex" flexDirection="row" gap="0.25rem" alignItems="center">
          <Typography>{t('Balance: ')}</Typography>
          <CurrencyField source="balance" coefficient={-1} currencySource="currency" fontWeight="600" />
        </Box>
        <IdentifyButton />
      </Box>
    </FieldsGroup>
  );
};

const UnidentifiedAccountEdit = () => (
  <Edit title={<Title />} appLocation="home.unidentified-account.edit">
    <SimpleShowLayout>
      <UnidentifiedAccountFieldsGroup />
    </SimpleShowLayout>
  </Edit>
);

export default UnidentifiedAccountEdit;
