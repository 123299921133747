import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { useConstantContext } from '../ConstantsContext';

const styles = {
  invoiceService: { marginRight: '5px', backgroundColor: '#DBE1F3' },
  referred: { marginRight: '5px', backgroundColor: '#FFF1BA' },
};

const InvoiceServiceChip = ({
  isFinanced,
  hasInvoiceServiceAttribute,
  applicationStatus,
  t,
}) => {
  const { constants } = useConstantContext();

  const {
    decisionStatuses,
  } = constants;

  if (!hasInvoiceServiceAttribute || isFinanced) return null;

  if (applicationStatus === decisionStatuses.REFERRED) {
    return <Chip style={styles.referred} label={t('Referred service')} />;
  }

  return <Chip style={styles.invoiceService} label={t('Service')} />;
};

InvoiceServiceChip.propTypes = {
  isFinanced: PropTypes.bool.isRequired,
  hasInvoiceServiceAttribute: PropTypes.bool.isRequired,
  applicationStatus: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default InvoiceServiceChip;
