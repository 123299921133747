import React from 'react';
import { useRecordContext } from 'react-admin';
import { useTranslation } from 'react-i18next';
import TitleH1 from '../../designSystem/TitleH1';
import { useConstantContext } from '../../ConstantsContext';
import invoiceServiceChip from '../../utils/InvoiceServiceChip';

export const getFinancingApplicationTitle = (record) => record?.orderReferenceNumber || '';

const FinancingApplicationTitle = () => {
  const record = useRecordContext();
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { currentDecision, hasInvoiceServiceAttribute } = record || {};
  const {
    decisionStatuses,
  } = constants;
  const title = `${t('Financing application')}: ${getFinancingApplicationTitle(record)}`;

  const isFinanced = currentDecision === decisionStatuses.GRANTED;

  const invoiceServiceChipComponent = invoiceServiceChip({
    isFinanced,
    hasInvoiceServiceAttribute,
    applicationStatus: currentDecision,
    t,
  });
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <TitleH1 title={title} sx={{ marginRight: '1rem' }} />
      {invoiceServiceChipComponent}
    </div>
  );
};

export default FinancingApplicationTitle;
