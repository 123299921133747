import React from 'react';

import {
  ReferenceField,
  FunctionField,
  TextField,
  useResourceContext,
} from 'react-admin';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FieldsGroup from '../utils/FieldsGroup';
import CurrencyField from '../utils/CurrencyField';
import { percentageFormat } from '../utils/PercentageField';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import EmptiableReferenceField from '../utils/EmptiableReferenceField';

import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';
import useHasUserPermissions from '../hooks/useHasUserPermissions';

import { useConstantContext } from '../ConstantsContext';
import { isNumber } from '../../utils/validators';

import InsuranceGroup from './InsuranceGroup';

const AssignedCreditInfo = () => {
  const resource = useResourceContext();
  const { hasFactoring, isMerchant } = useUserPermissionsAndRoles();
  const { constants } = useConstantContext();
  const { userActions } = constants;
  const { t } = useTranslation();

  const canViewAssignedCreditLimit = useHasUserPermissions(
    constants,
    userActions.VIEW_CUSTOMERS,
  ) && isMerchant;

  if (!hasFactoring || !canViewAssignedCreditLimit) return null;

  return (
    <FieldsGroup title={t('Credit and insurance limit')}>
      <EmptiableReferenceField
        label={false}
        source="id"
        reference="entity-v2/assigned-credit-info"
        link={false}
      >
        <Grid container>
          <Grid item lg={3} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="assignedCreditLimit" currencySource="assignedCreditLimitCurrency" label={t('Credit limit')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={3} md={6}>
            <SimpleShowLabeled>
              <FunctionField label={`${t('Utilised credit')} (%)`} render={(record = {}) => (isNumber(record.assignedCreditLimitUtilisedPercentage) ? percentageFormat(record.assignedCreditLimitUtilisedPercentage) : '-')} />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={3} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="assignedCreditLimitAvailable" currencySource="assignedCreditLimitCurrency" label={t('Available credit')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={3} md={6}>
            <SimpleShowLabeled>
              <CurrencyField source="assignedCreditLimitUtilised" currencySource="assignedCreditLimitCurrency" label={t('Utilised credit')} emptyText="-" />
            </SimpleShowLabeled>
          </Grid>
          <Grid item lg={3} md={6}>
            <SimpleShowLabeled>
              <ReferenceField
                label={t('Financer')}
                source="financerId"
                reference="entity-v2/reference"
                link={false}
                emptyText="-"
              >
                <TextField source="mainAddress.name" />
              </ReferenceField>
            </SimpleShowLabeled>
          </Grid>
        </Grid>
        <InsuranceGroup entityResource={resource} />
      </EmptiableReferenceField>
    </FieldsGroup>
  );
};

export default AssignedCreditInfo;
