import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  ArrayField,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import SimpleShowLabeled from '../utils/SimpleShowLabeled';
import CurrencyField from '../utils/CurrencyField';
import PercentageField from '../utils/PercentageField';

import BooleanField from '../designSystem/react-admin/BooleanField';
import TitleH3 from '../designSystem/TitleH3';
import DateField from '../designSystem/react-admin/DateField';
import { EntityReferenceField } from '../designSystem/react-admin/ReferenceField';
import Datagrid from '../designSystem/react-admin/Datagrid';

import { useConstantContext } from '../ConstantsContext';
import resources from '../../constants/resources';
import useUserPermissionsAndRoles from '../hooks/useUserPermissionsAndRoles';

const InsuranceGroup = ({ entityResource }) => {
  const { t } = useTranslation();
  const { constants } = useConstantContext();
  const { isFinancer, isMerchant } = useUserPermissionsAndRoles();
  const { insuranceStatuses } = constants;

  if (!entityResource.endsWith(resources.CUSTOMER)) return null;

  return (
    <>
      <Box display="flex" flexDirection="column" marginTop="3.5rem">
        <TitleH3 title={t('Insurances')} />
        <SimpleShowLabeled>
          <ArrayField source="insurances" label={false}>
            <Datagrid
              bulkActionButtons={false}
              rightAlignedColumns={['lastUpdatedDate']}
              rowStyle={(row) => ({
                opacity: row.status === insuranceStatuses.INACTIVE ? 0.5 : 1,
              })}
            >
              {isFinancer && (
                <EntityReferenceField
                  label={t('Owner')}
                  source="entityOwnerId"
                  reference="entity-v2"
                />
              )}
              {isFinancer && (
                <EntityReferenceField
                  label={t('Assigned merchant')}
                  source="assignedMerchantId"
                  reference="entity-v2"
                />
              )}
              <TextField source="insuranceProvider" label={t('Provider')} emptyText="-" />
              <CurrencyField source="creditInsuranceLimit" currencySource="creditInsuranceLimitCurrency" label={t('Insurance limit')} />
              <CurrencyField source="uninsuredAmount" currencySource="creditInsuranceLimitCurrency" label={t('Uninsured amount')} />
              {isMerchant && <PercentageField source="utilisedAmountPercentage" label={t('Utilised insurance')} digitsPrecision={2} />}
              {isFinancer && <CurrencyField source="utilisedAmount" currencySource="creditInsuranceLimitCurrency" label={t('Utilised insurance')} />}
              <DateField source="insuranceStartDate" label={t('Start date')} emptyText="-" />
              <DateField source="insuranceEndDate" label={t('End date')} emptyText="-" />
              <DateField showTime source="lastUpdatedDate" label={t('Last update')} emptyText="-" />
            </Datagrid>
          </ArrayField>
        </SimpleShowLabeled>
      </Box>
      <Box display="flex" flexDirection="column" margin="3.5rem 0rem">
        <BooleanField source="settings.nonInsurableCompany" label={t('Non-insurable company')} />
        <BooleanField source="settings.financialInstitution" label={t('Financial institution')} />
        <BooleanField source="settings.privateBusiness" label={t('Private business')} />
      </Box>
    </>
  );
};

InsuranceGroup.propTypes = {
  entityResource: PropTypes.string.isRequired,
};

export default InsuranceGroup;
