import store from './store';

/**
 * Decode a JWT token and return its payload.
 * @param {string} token - The JWT token.
 * @returns {Object} Decoded token payload.
 * @throws {Error} If the token is invalid or cannot be decoded.
 */
export const decodeToken = (token) => {
  try {
    const payload = JSON.parse(window.atob(token.split('.')[1]));
    return payload;
  } catch (err) {
    return null;
  }
};

/**
 * Build query parameters from the token payload.
 * @param {Object} payload - The decoded token payload.
 * @returns {string[]} Array of query parameters.
 */
export const buildQueryParams = (payload) => {
  const params = [];

  // Add roles
  if (payload.entity_roles.includes('financer')) {
    params.push('financer=true');
  }
  if (payload.entity_roles.includes('merchant')) {
    params.push('merchant=true');
  }

  // Add entity name
  if (payload.entity_name) {
    params.push(`entity=${encodeURIComponent(payload.entity_name)}`);
  }

  return params;
};

/**
 * Update the URL hash with the provided query parameters.
 * @param {string[]} params - Array of query parameters.
 */
export const updateHash = (params) => {
  const hash = window.location.hash || '#';
  const separator = hash.includes('?') ? '&' : '?';
  window.location.hash = `${hash}${separator}${params.join('&')}`;
};

/**
 * Handle the login process, including token decoding and hash updates.
 * @param {Function} login - Function to perform the login operation.
 * @param {string} email - User's email.
 * @param {string} password - User's password.
 * @param {Function} setError - Function to set error messages.
 * @param {Function} t - Translation function for error messages.
 */
export const handleLogin = async (login, email, password, setError, t) => {
  try {
    await login({ username: email, password });

    // Try to set URL params, but don't block login if it fails
    try {
      const token = store.getItem('token');
      if (token) {
        const tokenPayload = decodeToken(token);
        if (tokenPayload) {
          const params = buildQueryParams(tokenPayload);
          if (params.length > 0) {
            updateHash(params);
          }
        }
      }
    } catch (err) {
      // Silently fail - URL params are not critical for login
    }
  } catch (err) {
    setError(t('Invalid email or password'));
  }
};
