import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import InputsGroup from '../designSystem/InputsGroup';
import BooleanInput from '../designSystem/react-admin/inputs/BooleanInput';

import resources from '../../constants/resources';

const InsuranceSettingsFields = ({ entityResource }) => {
  const { t } = useTranslation();

  if (!entityResource.endsWith(resources.CUSTOMER)
    && !entityResource.endsWith(resources.CUSTOMER_APPLICATION)) return null;

  return (
    <>
      <InputsGroup marginTop="1rem">
        <BooleanInput
          label={t('Non-insurable company')}
          source="settings.nonInsurableCompany"
          helperText="Activate this if no insurance is needed for this company type"
        />
        <BooleanInput
          label={t('Financial institution')}
          source="settings.financialInstitution"
          helperText="Activate if this company is a financial institution"
        />
        <BooleanInput
          label={t('Private business')}
          source="settings.privateBusiness"
          helperText="A business owned and run by one person, where the owner is personally responsible for the business's debts and obligations"
        />
      </InputsGroup>
    </>
  );
};

InsuranceSettingsFields.propTypes = {
  entityResource: PropTypes.string.isRequired,
};

export default InsuranceSettingsFields;
