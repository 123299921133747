import React, { Children, useCallback } from 'react';
import {
  SimpleFormIterator as RaSimpleFormIterator,
  useSimpleFormIteratorItem,
  useSimpleFormIterator,
  IconButtonWithTooltip,
  useRecordContext,
} from 'react-admin';
import PropTypes from 'prop-types';
import { get, set } from 'lodash';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material';
import InputsGroup from '../InputsGroup';
import { ChildrenProp } from '../../../utils/helpers/propTypes';
import { titleH3Style } from '../../../theme';

const REMOVE_ICON_SIZE = '1rem';

const RemoveItemButton = ({ disabledCondition, sourcePrefix, setItemIndex }) => {
  const { remove, index } = useSimpleFormIteratorItem();
  const { t } = useTranslation();

  const record = useRecordContext();

  const shouldDisableRemoveButton = disabledCondition(get(record, `${sourcePrefix}.${index}`));

  const onClick = useCallback(() => {
    remove();
    setItemIndex(index);
  }, [index, remove, setItemIndex]);

  return (
    <IconButtonWithTooltip label={shouldDisableRemoveButton ? t('You cannot remove this item') : t('Delete')} onClick={shouldDisableRemoveButton ? () => { } : onClick}>
      <DeleteOutlineIcon
        color={shouldDisableRemoveButton ? 'grey.main' : 'secondary'}
        sx={{ width: REMOVE_ICON_SIZE, height: REMOVE_ICON_SIZE }}
      />
    </IconButtonWithTooltip>
  );
};

RemoveItemButton.propTypes = {
  disabledCondition: PropTypes.func,
  sourcePrefix: PropTypes.string.isRequired,
  setItemIndex: PropTypes.func.isRequired,
};

RemoveItemButton.defaultProps = {
  disabledCondition: () => false,
};

const AddButton = ({ label, sourceKeys, defaultValue }) => {
  const { add } = useSimpleFormIterator();
  const onClickAdd = useCallback(() => {
    const newValue = defaultValue || (sourceKeys
      ? sourceKeys.reduce((res, sourceKey) => set(res, sourceKey, ''), {})
      : '');
    return add(newValue);
  }, [add, sourceKeys, defaultValue]);

  return (
    <Button
      variant="text"
      color="secondary"
      onClick={onClickAdd}
      startIcon={<AddIcon />}
    >
      {label}
    </Button>
  );
};

AddButton.propTypes = {
  label: PropTypes.string.isRequired,
  sourceKeys: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.arrayOf(PropTypes.shape({})),
};

AddButton.defaultProps = {
  sourceKeys: undefined,
  defaultValue: undefined,
};

const SimpleFormIterator = ({
  addButtonLabel,
  itemLabel,
  disabledCondition,
  children,
  source: sourcePrefix,
  enableBackgroundColor,
  customDefaultValue,
  ...restProps
}) => {
  if (!sourcePrefix) throw new Error('SimpleFormIterator must have a source');

  // R-A use a div under the hood so we cannot use sx as a function with the theme
  const theme = useTheme();
  const [itemIndex, setItemIndex] = React.useState(0);

  let sourceKeys;
  if (Children.count(children) === 1
    && Children.only(children).type === InputsGroup) {
    const inputsGroupChild = Children.only(children);
    sourceKeys = Children.map(inputsGroupChild.props.children, (child) => {
      if (!child) return null;
      if (!child.props.source) throw new Error('A child of an InputsGroup within a SimpleFormIterator must have a source');
      return child.props.source;
    }).filter((source) => !!source);
  }
  return (
    <RaSimpleFormIterator
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      source={sourcePrefix}
      getItemLabel={(index) => {
        setItemIndex(index + 1);
        return (<>{itemLabel} ({index + 1})</>);
      }}
      removeButton={<RemoveItemButton disabledCondition={disabledCondition} sourcePrefix={sourcePrefix} setItemIndex={setItemIndex} />}
      addButton={<AddButton label={addButtonLabel} sourceKeys={sourceKeys} defaultValue={customDefaultValue} />}
      disableClear
      disableReordering
      sx={{
        ...enableBackgroundColor && {
          '& .RaSimpleFormIterator-list': {
            backgroundColor: theme.palette.hover,
            ...itemIndex && { padding: '1.25rem' },
          },
        },
        '& .RaSimpleFormIterator-line': {
          flexDirection: 'column',
          position: 'relative', // Necessary to place the Action buttons properly
          marginBottom: '1.5rem',
          ':last-child': { marginBottom: 0 },
        },
        '& .RaSimpleFormIterator-action': {
          position: 'absolute',
          left: 0,
          top: '-0.3rem',
          margin: 0,
          visibility: 'visible', // Override on hover visible behaviour
        },
        '& .RaSimpleFormIterator-index': {
          ...titleH3Style,
          marginLeft: `calc(${REMOVE_ICON_SIZE} + 1rem + 0.3rem)`,
          marginBottom: '1.25rem',
          marginTop: 0,
          display: 'flex!important', // Override display none on small screens
        },
        '& .RaSimpleFormIterator-form': {
          marginBottom: '1.5rem',
        },
      }}
    >
      {children}
    </RaSimpleFormIterator>
  );
};

SimpleFormIterator.propTypes = {
  addButtonLabel: PropTypes.string.isRequired,
  itemLabel: PropTypes.string.isRequired,
  disabledCondition: PropTypes.func,
  children: ChildrenProp.isRequired,
  source: PropTypes.string,
  enableBackgroundColor: PropTypes.bool,
  customDefaultValue: PropTypes.arrayOf(PropTypes.object),
};

SimpleFormIterator.defaultProps = {
  disabledCondition: () => false,
  source: undefined,
  enableBackgroundColor: false,
  customDefaultValue: undefined,
};

export default SimpleFormIterator;
