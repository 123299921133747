import React, { useCallback } from 'react';

import {
  useRecordContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import { EditModalButton } from '../../designSystem/ModalButton';

import useCustomRpc from '../../hooks/useCustomRpc';
import useHasUserPermissions from '../../hooks/useHasUserPermissions';

import { useConstantContext } from '../../ConstantsContext';
import resources from '../../../constants/resources';
import InsuranceSettingsFields from '../../utils/InsuranceSettingsFields';

import KycFields from '../../utils/KycFields';

const ConfirmKycButton = () => {
  const record = useRecordContext();
  const { constants } = useConstantContext();
  const { t } = useTranslation();

  const {
    id: applicationId,
    applicationStep,
    currentDecision,
  } = record;
  const {
    resourceTypes,
    resourceTypePaths,
    applicationSteps,
    decisionStatuses,
    userActions,
  } = constants;

  const isCreated = applicationSteps.CREATED === applicationStep;
  const isDenied = decisionStatuses.DENIED === currentDecision;

  const {
    mutate: setKYC,
    isLoading,
  } = useCustomRpc({
    httpMethod: 'PUT',
    path: `${resourceTypePaths[resourceTypes.CUSTOMER_APPLICATION]}/${applicationId}/set-kyc`,
    shouldRefresh: true,
    errorMessage: t('Could not set the KYC'),
  });

  const onSubmit = useCallback((formData) => {
    const {
      creditScore,
      creditScoreProvider,
      paymentScore,
      paymentScoreProvider,
      financerScore,
      expectedVolume,
      insuranceStartDate,
      insuranceEndDate,
      ratingProvider,
      rating,
      creditQualityStep,
      settings,
    } = formData;
    setKYC({
      creditScore,
      creditScoreProvider,
      paymentScore,
      paymentScoreProvider,
      financerScore,
      expectedVolume,
      insuranceStartDate,
      insuranceEndDate,
      ratingProvider,
      rating,
      creditQualityStep,
      nonInsurableCompany: settings.nonInsurableCompany,
      financialInstitution: settings.financialInstitution,
      privateBusiness: settings.privateBusiness,
    });

    return true;
  }, [setKYC]);

  const canEditCustomerApplication = useHasUserPermissions(
    constants,
    userActions.EDIT_CUSTOMER_APPLICATIONS,
  );

  if (!canEditCustomerApplication) return null;

  return (
    <EditModalButton
      modalTitle={t('Insurance and KYC')}
      onClick={onSubmit}
      disabled={isLoading || !isCreated || isDenied}
      formDefaultValue={{
        creditScore: record.creditScore,
        creditScoreProvider: record.creditScoreProvider,
        paymentScore: record.paymentScore,
        paymentScoreProvider: record.paymentScoreProvider,
        financerScore: record.financerScore,
        expectedVolume: record.expectedVolume,
        insuranceStartDate: record.insuranceStartDate,
        insuranceEndDate: record.insuranceEndDate,
        ratingProvider: record.ratingProvider,
        rating: record.rating,
        creditQualityStep: record.creditQualityStep,
        settings: {
          nonInsurableCompany: record.nonInsurableCompany,
          financialInstitution: record.financialInstitution,
          privateBusiness: record.privateBusiness,
        },
      }}
      width="48rem"
    >
      <KycFields />
      <InsuranceSettingsFields entityResource={resources.CUSTOMER_APPLICATION} />
    </EditModalButton>
  );
};

export default ConfirmKycButton;
