import React from 'react';
import PropTypes from 'prop-types';
import {
  useRecordContext,
} from 'react-admin';

import DropDownMenu from '../../utils/DropDownMenu';

import RevertPaymentButton from './RevertPaymentButton';

const DropDownMenuUnidentifiedAccount = ({
  transactions,
  balance,
}) => {
  const { id, relatedTransaction } = useRecordContext();
  const transaction = transactions.find((t) => t.id === id);
  const isFirstTransaction = transactions.length > 1 && transactions[0].id === id;
  const hasIdentifiedTransactions = transaction?.identified
    && Object.keys(transaction.identified).length > 0;
  const alreadyReverted = !!relatedTransaction;

  if (balance === 0
    || alreadyReverted
    || hasIdentifiedTransactions
    || isFirstTransaction) return null;

  return (
    <DropDownMenu>
      <RevertPaymentButton />
    </DropDownMenu>
  );
};

DropDownMenuUnidentifiedAccount.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object).isRequired,
  balance: PropTypes.number.isRequired,
};
export default DropDownMenuUnidentifiedAccount;
