module.exports = {
  apiBase: 'https://api.dev.fimento.com',
  apiKey: '#ecn!VKRx&mNqv6fV6vvPv7u',
  sentryDsn: 'https://78f500addb5f477d8cf2e15d21964fc9@o452401.ingest.sentry.io/6228437',
  sentryEnabled: true,
  sentryEnvironment: 'dev',
  canEditFeatureFlags: true,
  allowHotjar: true,
  fimentoClientId: '64707e166ef9de0d8dfbb5c7',
};
