import React from 'react';
import PropTypes from 'prop-types';

import { Loading, useRecordContext } from 'react-admin';
import { Box } from '@mui/material';
import { startCase, isEmpty, get } from 'lodash';
import { isDefined } from '../../utils/validators';
import { useConstantContext } from '../ConstantsContext';
import ListChip, { colors } from '../designSystem/ListChip';
import { ReactComponent as MoankIcon } from '../../../assets/m-partner-logo.svg';

const mapToChipColor = (decisionStatuses) => ({
  [decisionStatuses.REFERRED]: colors.orange,
  [decisionStatuses.GRANTED]: colors.green,
  [decisionStatuses.DENIED]: colors.red,
  [decisionStatuses.PARTNER_PENDING]: colors.gray,
  [decisionStatuses.PARTNER_GRANTED]: colors.green,
});

const LabelWithIcon = ({ label }) => (
  <Box
    display="flex"
    alignItems="center"
    flexDirection="row"
    gap="0.3rem"
  >
    <MoankIcon />
    {label}
  </Box>
);

LabelWithIcon.propTypes = {
  label: PropTypes.string.isRequired,
};

const ApplicationStatusChip = ({ source, subSource }) => {
  const record = useRecordContext();
  const applicationStatus = get(record, source);
  const partnerDecision = get(record, subSource);
  const { constants } = useConstantContext();
  const { decisionStatuses } = constants;
  const {
    hasInvoiceServiceAttribute,
    currentDecision,
    financedAt,
  } = record.financing || record || {};

  if (!isDefined(applicationStatus)
    && !hasInvoiceServiceAttribute) return null;
  if (isEmpty(decisionStatuses)) return <Loading />;

  const isFinanced = financedAt
    || currentDecision === decisionStatuses.GRANTED;

  let chipColor = mapToChipColor(decisionStatuses)[applicationStatus] || colors.gray;
  let displayLabel = startCase(applicationStatus);

  if (hasInvoiceServiceAttribute && !isFinanced) {
    displayLabel = 'Service';
    chipColor = colors.blue;
  }
  if (hasInvoiceServiceAttribute
    && !isFinanced
    && (currentDecision === decisionStatuses.REFERRED
      || applicationStatus === decisionStatuses.REFERRED)) {
    displayLabel = 'Referred service';
    chipColor = colors.orange;
  }
  if (applicationStatus === decisionStatuses.REFERRED && partnerDecision) {
    if (partnerDecision === decisionStatuses.DENIED) displayLabel = <LabelWithIcon label="Denied order" />;
    if (partnerDecision === decisionStatuses.PARTNER_CUSTOMER_DENIED) displayLabel = <LabelWithIcon label="Denied cust." />;
  }

  if (partnerDecision === decisionStatuses.PARTNER_GRANTED) displayLabel = <LabelWithIcon label="Granted" />;
  if (applicationStatus === decisionStatuses.PARTNER_PENDING) displayLabel = <LabelWithIcon label="Pending" />;

  return (
    <ListChip
      label={displayLabel}
      chipColor={chipColor}
      labelWidth={subSource && '6.5rem'}
      reducePadding={subSource}
    />
  );
};

ApplicationStatusChip.propTypes = {
  source: PropTypes.string.isRequired,
  subSource: PropTypes.string,
};

ApplicationStatusChip.defaultProps = {
  subSource: null,
};

export default ApplicationStatusChip;
