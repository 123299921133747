import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';

import { useRecordContext } from 'react-admin';

import currencyFormat from '../../utils/currencyFormat';
import { isNumber } from '../../utils/validators';
import roundNumber from '../../utils/roundNumber';

const colours = {
  OWED: 'green',
  BALANCED: 'orange',
  PAYOUT: 'red',
  INVALID: 'red',
};

export const colorSwticherFlag = {
  SETTLEMENT_ACCOUNT: 'settlementAccount',
  UNIDENTIFIED_PAYMENT: 'unidentifiedPayment',
};

const colorSwticher = (flag, value) => {
  switch (flag) {
    case colorSwticherFlag.SETTLEMENT_ACCOUNT:
      if (value > 0) return colours.OWED;
      if (value === 0) return colours.BALANCED;
      return colours.PAYOUT;

    case colorSwticherFlag.UNIDENTIFIED_PAYMENT:
      return colours.INVALID;

    default:
      return null;
  }
};

const CurrencyField = ({
  source,
  currencySource,
  emptyText,
  coefficient,
  currency,
  isRounded,
  customRecord,
  colorSwticherFlag: flag,
  fontWeight,
}) => {
  let record = useRecordContext();
  if (customRecord) record = customRecord;
  const currencyValue = currency ?? get(record, currencySource);
  if (!currencyValue) {
    return (
      <Typography
        component="span"
        variant="body2"
      >
        {emptyText}
      </Typography>
    );
  }

  const value = isRounded ? roundNumber(get(record, source), 4) : get(record, source);

  const colour = colorSwticher(flag, value);

  return (
    <Typography
      component="span"
      variant="body2"
      fontWeight={fontWeight || '400'}
      sx={{
        ...colour && { color: colour },
      }}
    >
      {!isNumber(value) ? emptyText : currencyFormat(
        value === 0 ? 0 : value * coefficient, // Do not transform 0 in -0
        currencyValue,
      )}
    </Typography>
  );
};

CurrencyField.propTypes = {
  source: PropTypes.string.isRequired,
  currencySource: PropTypes.string,
  currency: PropTypes.string,
  emptyText: PropTypes.string,
  coefficient: PropTypes.number,
  isRounded: PropTypes.bool,
  customRecord: PropTypes.shape({}),
  fontWeight: PropTypes.string,
  colorSwticherFlag: PropTypes.string,
};

CurrencyField.defaultProps = {
  currency: undefined,
  currencySource: '',
  emptyText: '',
  coefficient: 1,
  isRounded: false,
  customRecord: undefined,
  fontWeight: undefined,
  colorSwticherFlag: undefined,
};

export default CurrencyField;
