import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  required,
  maxValue,
  useResourceContext,
} from 'react-admin';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import ModalButton from '../designSystem/ModalButton';

import useCustomRpc from '../hooks/useCustomRpc';

import { strictlyPositiveNumberValidation } from '../../utils/validationErrors';

import CurrencyInput from '../designSystem/react-admin/inputs/CurrencyInput';
import TitleH4 from '../designSystem/TitleH4';

const validateRequired = [required()];

const PayViaSettlement = ({ accountRecord }) => {
  const { t } = useTranslation();
  const resource = useResourceContext();

  const {
    id,
    balance,
    currency,
  } = accountRecord;

  const {
    mutate: directPayment,
    isLoading,
  } = useCustomRpc({
    path: `${resource}/${id}/direct-payment`,
    httpMethod: 'POST',
    shouldRefresh: true,
    errorMessage: t('Could not pay via settlement'),
  });

  const onSubmit = useCallback((formData) => {
    const data = {
      amount: formData.directPaymentAmount,
    };
    directPayment(data);
    return true;
  }, [directPayment]);

  return (
    <ModalButton
      modalTitle={t('Pay via settlement account')}
      openButtonLabel={t('Pay via settlement')}
      actionLabel={t('Make payment')}
      variant="subAction"
      onClick={onSubmit}
      disabled={isLoading}
      withForm
      width="47.5rem"
      formDefaultValue={{
        currency,
        directPaymentAmount: balance,
      }}
    >
      <TitleH4 title={t("Balance this order in the settlement account. This will create a transaction that deducts the selected amount from the merchant's upcoming payout. The order will be marked as paid if the total amount is fully balanced")} />
      <Box
        maxWidth="50%"
      >
        <CurrencyInput
          label={t('Amount')}
          source="directPaymentAmount"
          placeholder={t('The amount')}
          currency={currency}
          validate={[
            maxValue(Math.abs(balance)),
            strictlyPositiveNumberValidation,
            ...validateRequired,
          ]}
        />
      </Box>
    </ModalButton>
  );
};

PayViaSettlement.propTypes = {
  accountRecord: PropTypes.shape({
    id: PropTypes.string.isRequired,
    creditorId: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    financialProduct: PropTypes.string.isRequired,
  }),
};

PayViaSettlement.defaultProps = {
  accountRecord: {},
};

export default PayViaSettlement;
