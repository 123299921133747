import { fetchUtils } from 'react-admin';
import jsonServerRestClient from 'ra-data-json-server';

import dayjs from './utils/dayjs';

import addUploadFeature from './addUploadFeature';

import getAccessToken from './utils/getAccessToken';

import config from '../config';

const httpClient = async (url, options = {}) => {
  const headers = options.headers || new window.Headers({ Accept: 'application/json' });
  headers.set('X-API-Key', config.apiKey);
  headers.set('X-Token', await getAccessToken());
  return fetchUtils.fetchJson(url, {
    ...options,
    headers,
  });
};

const modifiedJsonServerRestClient = (apiUrl, _httpClient) => ({
  ...jsonServerRestClient(apiUrl, _httpClient),
  create: async (resource, params) => {
    const { json } = await _httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    });

    return { data: json };
  },
  customRpc: async ({
    httpMethod,
    path,
    data,
  }) => {
    const { json } = await _httpClient(`${apiUrl}/${path}`, {
      method: httpMethod,
      body: JSON.stringify(data),
    });
    return { data: json };
  },
  uploadDocument: async (files) => {
    const formData = new window.FormData();
    files.forEach(({ fileName, file }) => {
      formData.append(fileName || dayjs().format(), file);
    });

    const { json } = await _httpClient(`${apiUrl}/upload`, {
      method: 'POST',
      body: formData,
    });

    return { data: json };
  },
});

const dataProvider = addUploadFeature(modifiedJsonServerRestClient(config.apiBase, httpClient));

export default dataProvider;
