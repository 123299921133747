import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ReactComponent as EmptyFile } from '../../assets/emptyFile.svg';

const EmptyData = ({ isInvoiceServiceWithoutApplication }) => {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <EmptyFile />
      <Typography>{isInvoiceServiceWithoutApplication ? t('Invoice service, no application avaliable') : t('No data available')}</Typography>
    </Box>
  );
};

EmptyData.propTypes = {
  isInvoiceServiceWithoutApplication: PropTypes.bool,
};

EmptyData.defaultProps = {
  isInvoiceServiceWithoutApplication: false,
};

export default EmptyData;
