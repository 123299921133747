import React from 'react';

import { useTranslation } from 'react-i18next';

import TextInput from '../designSystem/react-admin/inputs/TextInput';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import InputsGroup from '../designSystem/InputsGroup';
import CurrencyInput from '../designSystem/react-admin/inputs/CurrencyInput';

import { useConstantContext } from '../ConstantsContext';

import validateKycField from './validateKycField';

const KycFields = (
) => {
  const { choices, clientInfo } = useConstantContext();
  const { t } = useTranslation();
  const {
    creditScoreProviders,
    paymentScoreProviders,
    ratingProviders,
    creditQualitySteps,
  } = choices;

  const validate = validateKycField();
  return (
    <>
      <InputsGroup layout="column">
        <SelectInput
          label={t('Credit score provider')}
          source="creditScoreProvider"
          choices={creditScoreProviders}
          validate={validate}
          placeholder="Select"
        />
        <TextInput
          label={t('Credit score')}
          source="creditScore"
          validate={validate}
        />
        <SelectInput
          label={t('Payment score provider')}
          source="paymentScoreProvider"
          choices={paymentScoreProviders}
          validate={validate}
          placeholder="Select"
        />
        <TextInput
          label={t('Payment score')}
          source="paymentScore"
          validate={validate}
        />
        <TextInput
          label={t('Financer score')}
          source="financerScore"
        />
        <CurrencyInput
          currency={clientInfo.referenceCurrency}
          label={t('Expected monthly order volume')}
          source="expectedVolume"
          placeholder="E.g 2000"
        />
      </InputsGroup>
      <InputsGroup layout="column" title={t('Rating')}>
        <SelectInput
          label={t('Rating provider')}
          source="ratingProvider"
          choices={ratingProviders}
          validate={validate}
          placeholder="Select"
        />
        <TextInput
          label={t('Rating')}
          source="rating"
          validate={validate}
        />
        <SelectInput
          label={t('Credit quality step (1-6)')}
          choices={creditQualitySteps}
          source="creditQualityStep"
          placeholder="Select 1-6"
        />
      </InputsGroup>
    </>
  );
};

export default KycFields;
