import { useStore } from 'react-admin';

const featureFlagsInitialState = {
  enableLanguage: false,
  enableDeliveryConfirmation: false,
  enableAnalyticsAndRedesign: true,
  enableVAT: true,
  enableBillingList: true,
  enablePaybackOverpayment: true,
  enableBillingInvoicing: true,
  enablePartialPayout: true,
  enablePartnerFinancingKpi: true,
  enableDiscountUnidentifiedAccount: true,
};

const useFeatureFlags = () => useStore('fimento.featureFlags', featureFlagsInitialState);

export default useFeatureFlags;
