import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  required,
} from 'react-admin';
import Add from '@mui/icons-material/Add';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import dayjs from '../../utils/dayjs';

import { useConstantContext } from '../ConstantsContext';
import ReportsFilterContextProvider from './filters/ReportsFilterContextProvider';
import useReportsFilterContext from './filters/useReportsFilterContext';

import useCustomRpc from '../hooks/useCustomRpc';

import ModalButton from '../designSystem/ModalButton';
import SelectInput from '../designSystem/react-admin/inputs/SelectInput';
import InputsGroup from '../designSystem/InputsGroup';
import Button from '../designSystem/Button';

const ReportTypeInput = () => {
  const { choices: { clientReportTypes } } = useConstantContext();
  const { t } = useTranslation();
  const { setValue, clearErrors } = useFormContext();
  const {
    setDisplayedFilters,
    mapReportsToFilters,
  } = useReportsFilterContext();
  const availableReportTypes = clientReportTypes
    .sort((report1, report2) => report1.name.localeCompare(report2.name));

  const onChangeReportType = useCallback((e) => {
    // 1. No need to update the filtersForReportType as it is done in the useEffect of the context
    // 2. We want these side effects only on report type change,
    //    hence not having it in the useEffect of the context
    const newFiltersForReportType = mapReportsToFilters[e.target.value] || [];
    setDisplayedFilters(newFiltersForReportType
      .filter((filterForReportType) => filterForReportType.props.alwaysOn));
    setValue('filters', {});
    setValue('date', undefined);
    clearErrors();
  }, [mapReportsToFilters, setDisplayedFilters, setValue, clearErrors]);

  return (
    <SelectInput
      alwaysOn
      label={t('Report type')}
      source="type"
      choices={availableReportTypes}
      validate={[required()]}
      onChange={onChangeReportType}
    />
  );
};

const AddFiltersButton = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const onOpen = useCallback((e) => { setAnchorEl(e.currentTarget); }, []);
  const onClose = useCallback(() => { setAnchorEl(null); }, []);
  const {
    filtersForReportType,
    displayedFilters,
    setDisplayedFilters,
    hiddenFilters,
    setHiddenFilters,
  } = useReportsFilterContext();
  useEffect(() => {
    const newHiddenFilters = filtersForReportType.filter((filterForReportType) => {
      const isDisplayed = displayedFilters
        .findIndex(({ props: { source } }) => filterForReportType.props.source === source) !== -1;
      return !isDisplayed;
    });
    setHiddenFilters(newHiddenFilters);
    const hasNoHiddenFilters = filtersForReportType.length > 0 && newHiddenFilters.length === 0;
    if (hasNoHiddenFilters) onClose();
  }, [displayedFilters, filtersForReportType, onClose, setHiddenFilters]);

  const onClickShowFilter = useCallback((e) => {
    const filterToAdd = filtersForReportType
      .find((filter) => filter.props.source === e.currentTarget.id);
    setDisplayedFilters([...displayedFilters, filterToAdd]);
    onClose();
  }, [displayedFilters, setDisplayedFilters, filtersForReportType, onClose]);

  if (hiddenFilters.length === 0) return null;

  return (
    <Box>
      <Button
        variant="blueBackgroundOnHover"
        onClick={onOpen}
        startIcon={<Add />}
      >
        {t('Add filters')}
      </Button>
      <Menu
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        {hiddenFilters.map((subFilterInput) => (
          <MenuItem
            id={subFilterInput.props.source}
            onClick={onClickShowFilter}
            key={subFilterInput.props.source}
          >
            {subFilterInput.props.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const ReportsFiltersInputs = () => {
  const { displayedFilters, handleHide } = useReportsFilterContext();

  if (displayedFilters.length === 0) return null;

  return (
    <InputsGroup layout="column" marginTop="1rem">
      {displayedFilters.map((filter) => React.cloneElement(
        filter,
        { handleHide: filter.props.alwaysOn ? undefined : handleHide, key: filter.props.source },
      ))}
    </InputsGroup>
  );
};

const GenerateReport = () => {
  const { t } = useTranslation();
  const {
    mutate: generateReport,
  } = useCustomRpc({
    httpMethod: 'POST',
    path: 'report/generate',
    errorMessage: t('Could not retrieve report file'),
    successMessage: t('Report is being generated. You will get a notification when it is done'),
    shouldRefresh: true,
  });

  const onSubmit = useCallback((data) => {
    const serializeFilters = (item) => {
      const result = {};
      Object.entries(item).forEach(([key, value]) => {
        if (!value) return;
        if (dayjs.isDayjs(value)) {
          result[key] = value.format('YYYY-MM-DD');
        } else if (typeof value === 'object' && !Array.isArray(value)) {
          result[key] = value.id;
        } else result[key] = value;
      });
      return result;
    };
    generateReport({
      date: dayjs(data.date).format('YYYY-MM-DD'),
      type: data.type,
      filters: JSON.stringify(serializeFilters(data.filters || {})),
    });
    return true;
  }, [generateReport]);

  return (
    <ModalButton
      openButtonLabel={t('Generate report')}
      actionLabel={t('Generate')}
      modalTitle={t('Generate report')}
      width="47.5rem"
      onClick={onSubmit}
      formDefaultValue={{}}
    >
      <ReportsFilterContextProvider>
        <InputsGroup layout="column">
          <ReportTypeInput />
        </InputsGroup>
        <ReportsFiltersInputs />
        <AddFiltersButton />
      </ReportsFilterContextProvider>
    </ModalButton>
  );
};

export default GenerateReport;
