import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { titleH3Style } from '../../theme';

const TitleH3 = ({
  title,
  color,
  marginBottom,
  fontWeight,
  ...rest
}) => (
  <Typography
    marginBottom={marginBottom}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    fontSize={titleH3Style.fontSize}
    fontWeight={fontWeight || titleH3Style.fontWeight}
    color={color}
  >
    {title}
  </Typography>
);

TitleH3.propTypes = {
  title: PropTypes.string.isRequired,
  marginBottom: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
};

TitleH3.defaultProps = {
  color: titleH3Style.color,
  marginBottom: '0.5rem',
  fontWeight: undefined,
};

export default TitleH3;
